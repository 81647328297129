.iframe-container {
    position: relative;
    width: 80%;
    padding-bottom: 50%; /* 16:9 aspect ratio */
    margin-bottom: 2rem;
}

.iframe-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
